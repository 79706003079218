<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Detección por muestreo"/><br>

            <div class = "text-center">
                <img class = "w-100" src="@/assets/manejoPlagas/muestreo/image-1.jpg" alt="">
            </div>

            <TitleText 
            title = "Detección por muestreo de frutos de mosca del Mediterráneo en Guatemala"
            text1 = "Es la actividad en la cual se colectan en el campo frutos de pericarpio suave y se manejan y trasladan apropiadamente al laboratorio, con la finalidad de detectar la plaga en sus estados inmaduros, y con ello evaluar y orientar acciones de erradicación. Esta actividad es un complemento al sistema de detección mediante el trampeo."
            numP = "1"
            />

            <div class = "text-center mt-5">
                <h2>Tipos de muestreo</h2>
            </div>
            

            <TitleText 
            title = "Muestreo General"
            text1 = "Consiste en colectar en una superficie delimitada de frutos de pericarpio suave, susceptibles a ser infestados por la mosca del Mediterráneo."
            text2 = "Este tipo de muestreo, debe realizarse cuando se inician trabajos en nuevas áreas infestadas por la plaga y por lo menos, debe tener duración de un año. De esta manera es posible obtener información de todos los hospedantes potenciales durante un ciclo completo."
            text3 = "El objetivo de este tipo de muestreo es identificar hospedantes de la mosca del Mediterráneo, en nuevas geografías de invasión, según el avance de dispersión que pudiera presentar la plaga. Este tipo de muestreo conlleva a determinar el gradiente de infestación según fenología, así como conocer los hospedantes predilectos y alternancia. Este tipo de muestreo permite también conocer la distribución, densidad y fenología de todos los frutos hospedantes presentes en una superficie determinada."
            numP = "3"
            />

            <TitleText 
            title = "Muestreo sistemático"
            text1 = "Consiste en colectar en una superficie delimitada en tiempo y lugar, la mayor diversidad de frutos hospedantes presentes y que por antecedentes de los resultados previos del tipo de muestreo general, se conoce son los predilectos de la plaga y se corrobora con este muestreo sistemático."
            text2 = "El objetivo de este muestreo es mantener una vigilancia sistemática en tiempo y lugar sobre las poblaciones silvestres de la plaga."
            numP = "2"
            />

            <TitleText 
            title = "Muestreo dirigido"
            text1 = "Este tipo de muestreo se lleva a cabo en el momento en que se informa de brotes y detecciones. Es una excelente herramienta de detección cuando la mosca del Mediterráneo se encuentra a niveles bajos de población y su distribución es limitada e irregular. Bajo estas circunstancias, se debe considerar como una actividad básica. El objetivo principal es determinar el establecimiento de la mosca y su dispersión, delimitar el área real de un brote y orientar las actividades de control."
            text2 = "Su área de acción se lleva a cabo como mínimo en un Km² cubriendo el 100% del área, colectando frutos de los hospedantes para corroborar la presencia o ausencia de la plaga o su avance hacia otros lugares."
            numP = "2"
            />
            
            <div class = "text-center mt-5">
                <h2>Realización del muestreo de frutos</h2>
            </div>

            <h5 class = "mt-5" style = "color:rgb(100,100,100);">Para realizar la actividad de colecta de muestras de frutos se procede de la manera siguiente:</h5>
            

            <p class = "mt-4">Al tomar una muestra se aplican los conocimientos sobre la biología y hábitos de la mosca del Mediterráneo. Las muestras se toman mediante la aplicación de criterios técnicos, apoyados en la experiencia del recolector de frutos. Cuando la plaga se encuentra a niveles difícilmente detectables y con una distribución limitada e irregular, se requiere seleccionar al máximo los frutos a muestrear. De acuerdo a la programación para el día, en primer lugar se deben ubicar en el lugar donde se va a tomar la muestra auxiliándose por medio de las hojas cartográficas escala 1:50,000. El siguiente paso es observar la disponibilidad de hospedante y proceder a obtener el permiso del propietario del área donde se va a recolectar la muestra.</p>

            <p class = "mb-4">Si hay varias especies de hospedante disponible se procede a obtener muestra de los que son preferidos por la mosca del Mediterráneo de acuerdo al cuadro de jerarquización de hospedantes, el cual fue obtenido de acuerdo al historial de larvas de varios años de muestreo de frutos. Después se seleccionan los frutos que presenten mayor probabilidad de estar infestados, siendo estos los frutos maduros.</p>

            <div class = "text-center mt-4">
                <img class = "w-100" src="@/assets/manejoPlagas/muestreo/image-2.jpg" alt="">
            </div>

            <p class = "mt-4">Al obtener los frutos se colocan en bolsas de manta y se procede a la identificación de la muestra, indicando la coordenada donde se recolectó la muestra, por medio de GPS, y se registra la altura sobre el nivel del mar, hospedante, lugar y número de frutos. Al tener todas las muestras de frutos del área muestreada son trasladadas al laboratorio de frutas, en donde se determinará si hay presencia de estados inmaduros de la mosca del Mediterráneo.</p>
        </div>
    </div>
</template>

<script>
import TitleText from "@/components/TitleText.vue";

export default {
    name: "Home",
    components: {
        TitleText,
    },
};
</script>